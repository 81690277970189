<template>
  <section class="dashboard">
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-home"></i>
        </span>
        Dashboard
      </h3>
      <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li
            class="breadcrumb-item active d-none d-sm-block d-sm-none"
            aria-current="page"
          >
            <span></span>Overview
            <i
              class="
                mdi mdi-alert-circle-outline
                icon-sm
                text-primary
                align-middle
              "
            ></i>
          </li>

          <li
            class="d-block d-sm-none d-none d-sm-block d-md-none"
            aria-current="page"
            @click="
              () => {
                this.$store.commit('setShowModal', { showModal: true });
              }
            "
          >
            <span></span>Cambiar fechas
            <i class="mdi mdi-calendar icon-sm text-primary align-middle"></i>
          </li>
        </ul>
      </nav>
    </div>
    <div class="row">
      <div class="col-md-2 stretch-card grid-margin">
        <div
          @click="
            () => {
              this.$router.push('sales');
            }
          "
          class="card bg-gradient-success card-img-holder text-white"
        >
          <div class="card-body">
            <img
              src="../../assets/images/dashboard/circle.svg"
              class="card-img-absolute"
              alt="circle-image"
            />
            <h4 class="font-weight-normal mb-3">
              Total Cerradas
              <!-- <i class="mdi mdi-diamond mdi-24px float-right"></i> -->
            </h4>
            <h4 class="mb-5">{{ numeral(closeTables).format("$ 0,0.00") }}</h4>
          </div>
        </div>
      </div>
      <div class="col-md-2 stretch-card grid-margin">
        <div
          @click="$router.push('cancelled-sales')"
          class="card bg-gradient-danger card-img-holder text-white"
        >
          <div class="card-body">
            <img
              src="../../assets/images/dashboard/circle.svg"
              class="card-img-absolute"
              alt="circle-image"
            />
            <h4 class="font-weight-normal mb-3">
              Total Anuladas
              <!-- <i class="mdi mdi-chart-line mdi-24px float-right"></i> -->
            </h4>
            <h4 class="mb-5">
              {{ numeral(cancellations).format("$ 0,0.00") }}
            </h4>
          </div>
        </div>
      </div>
      <div class="col-md-2 stretch-card grid-margin">
        <div class="card bg-gradient-info card-img-holder text-white">
          <div class="card-body">
            <img
              src="../../assets/images/dashboard/circle.svg"
              class="card-img-absolute"
              alt="circle-image"
            />
            <h4 class="font-weight-normal mb-3">
              Total Invitaciones
              <!-- <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i> -->
            </h4>
            <h4 class="mb-5">{{ numeral(guest).format("$ 0,0.00") }}</h4>
          </div>
        </div>
      </div>
      <div class="col-md-3 stretch-card grid-margin">
        <div
          @click="$router.push('open-tables')"
          class="card bg-gradient-secondary card-img-holder text-white"
        >
          <div class="card-body">
            <img
              src="../../assets/images/dashboard/circle.svg"
              class="card-img-absolute"
              alt="circle-image"
            />
            <h4 class="font-weight-normal mb-3">
              Total Abiertas
              <!-- <i class="mdi mdi-diamond mdi-24px float-right"></i> -->
            </h4>
            <h4 class="mb-5">{{ numeral(openTables).format("$ 0,0.00") }}</h4>
          </div>
        </div>
      </div>
      <div class="col-md-3 stretch-card grid-margin">
        <div class="card bg-gradient-primary card-img-holder text-white">
          <div class="card-body">
            <img
              src="../../assets/images/dashboard/circle.svg"
              class="card-img-absolute"
              alt="circle-image"
            />
            <h4 class="font-weight-normal mb-3">
              Resumen
              <!-- <i class="mdi mdi-diamond mdi-24px float-right"></i> -->
            </h4>
            <h4 class="mb-5">{{ numeral(total).format("$ 0,0.00") }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Productos</h4>
            <b-table
              responsive
              :items="productsList"
              :fields="fieldTableProducts"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <!-- A virtual composite column -->
              <template #cell(Precio)="data">
                {{ numeral(data.item.Precio).format("$ 0,0.00") }}
              </template>
            </b-table>
            <p>
              <b-pagination
                v-model="currentPage"
                :total-rows="productsList.length / perPage"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Productos por invitaciones</h4>
            <b-table
              responsive
              :items="guestProductsList"
              :fields="fieldTableGuest"
              :per-page="perPage"
              :current-page="currentPageGuest"
            >
              <!-- A virtual composite column -->
              <template #cell(Hora)="data">
                {{ moment(data.item.Hora).format("HH:mm A") }}
              </template>
              <template #cell(Valor)="data">
                {{ numeral(data.item.Valor).format("$ 0,0.00") }}
              </template>
              <template #cell(Precioiva)="data">
                {{
                  numeral(data.item.Valor * data.item.Cantidad).format(
                    "$ 0,0.00"
                  )
                }}
              </template>
            </b-table>
            <p>
              <b-pagination
                v-model="currentPageGuest"
                :total-rows="guestProductsList.length / perPage"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Metodos de Pago</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Metodo</th>
                    <th>Monto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="method in paymentsMethods" :key="method.Name">
                    <td>{{ method.Name }}</td>
                    <td>{{ numeral(method.Amount).format("$ 0,0.00") }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "dashboard",
  data() {
    return {
      products: [],
      guest: 0,
      openTables: 0,
      closeTables: 0,
      cancellations: 0,
      paymentsMethods: [],
      perPage: 7,
      currentPage: 1,
      currentPageGuest: 0,
      guestProducts: [],
      fieldTableProducts: [
        {
          key: "Descripcion",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "Cantidad",
          label: "Cantidad",
          sortable: true,
        },
        {
          key: "Categoria",
          label: "Categoria",
          sortable: true,
        },
        {
          key: "Precio",
          label: "Precio",
          sortable: true,
        },
      ],
      fieldTableGuest: [
        {
          key: "Descripcion",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "Cantidad",
          label: "Cantidad",
          sortable: true,
        },
        {
          key: "Valor",
          label: "Monto Individual",
          sortable: true,
        },
        {
          key: "Vendedor",
          label: "Vendedor",
          sortable: true,
        },
        {
          key: "Hora",
          label: "Hora",
          sortable: true,
        },
        {
          key: "Precioiva",
          label: "Total",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    loadDashboard() {
      // "/reports/home?fromDate=2021-01-01&toDate=2021-01-31"
      axios("/api/reports/home", {
        params: {
          fromDate: this.getOpenDate,
          toDate: this.getCloseDate,
          caja: this.getCaja,
        },
      }).then((response) => {
        response.data = response.data.data;
        this.products = response.data.products;
        this.guest = response.data.guest;
        this.openTables = response.data.openTables;
        this.closeTables = response.data.closeTables;
        this.cancellations = response.data.cancellations;
        this.paymentsMethods = response.data.paymentsMethods;
        this.guestProducts = response.data.guestProducts;
      });
    },
    numeral,
    moment,
  },
  created() {
    this.loadDashboard();
    if (this.getUser) {
      this.$store.dispatch("setEvent", { name: "open_dashboard" });
    }
  },
  computed: {
    total: function () {
      return this.closeTables + this.openTables;
    },
    productsList: function () {
      return this.products ? this.products : [];
    },
    guestProductsList: function () {
      return this.guestProducts ? this.guestProducts : [];
    },
    ...mapGetters(["getOpenDate", "getCloseDate", "getCaja", "getUser"]),
  },
  watch: {
    getOpenDate() {
      this.loadDashboard();
    },
    getCloseDate() {
      this.loadDashboard();
    },
    getCaja() {
      this.loadDashboard();
    },
    getUser() {
      this.$store.dispatch("setEvent", { name: "open_dashboard" });
    },
  },
};
</script>

<style scoped>
</style>